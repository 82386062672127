// import Typewriter from 'typewriter-effect';

// Components
// import Splash from './components/Splash.jsx'
// import Services from './components/Services.jsx'
// import AboutPage from './components/About.jsx'
// import Portfolio from "./components/Portfolio.jsx"
import ContactPage from 'components/Contact.jsx'

export default function Home() {
    return (
        <>
            {/* <Splash />
            <Services /> */}
            {/* <Portfolio /> */}
            {/* <AboutPage /> */}
            <ContactPage />
        </>
    )
}

import { useState, useMemo, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getStorage, ref, listAll, uploadBytesResumable, getDownloadURL, getMetadata } from "firebase/storage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faPlus, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import axios from 'axios'
import moment from 'moment'

import Loader from 'common/Loader.jsx'

import Styles from './_.module.sass'

const file_size_limit = 20     * 1000 * 1000 * 1000
//                      amount * gb   * mb   * kb

function formatKilobytes(total){
    let type = 'b'
    if(total > 1000){
        
        // To kilobytes
        if(total > 1000){
            total = total / 1000
            type = 'kb'
        }

        // To megabytes
        if(total > 1000){
            total = total / 1000
            type = 'mb'
        }

        // To gigabytes
        if(total > 1000){
            total = total / 1000
            type = 'gb'
            total = parseFloat(total).toFixed(1)
            if(total.endsWith('0')){
                total = total.slice(0, -2)
            }
        }
        else {
            total = parseFloat(total).toFixed(2)
        }
    }

    return total + type
}

export function Upload(){

    const storage = useMemo(() => getStorage(), []);
    const [ files, setFiles ]  = useState()
    const [ state, setState ] = useState({
        total: 0,
        current_uploaded: 0,
        uploading: false,
        finished: false,
        error_messages: []
    })
    const FileInput = useRef()

    // Calculate the new total!
    useEffect(() => {
        // console.log("Recalcuting total...")
        let total = 0

        if(files && Object.keys(files).length){
            total = Object
                .values(files)
                .reduce((prev, curr) => {
                    return prev + curr.size
                }, 0)
        }

        // total = formatKilobytes(total)

        setState((state) => {
            return { ...state, total }
        })
    }, [files])

    // Drag and drop listener
    useEffect(() => {
        if(state.uploading){ return }
        
        function dropListener(event){
            event.stopPropagation();
            event.preventDefault();

            document.querySelector('body').classList.remove(Styles.BodyDragging)
            try{
                let new_files = event.dataTransfer.files,
                    existing_files = { ...(files||{}) }

                Object.values(new_files).forEach(file => existing_files[file.name] = file)
                setFiles(existing_files)

                if(state.finished){
                    setState((s) => {
                        return { ...s, finished: false }
                    })
                }

            } catch(error){
                console.log("Unable to process dropped files! ", error)
            }
        }
        function dragEnter(event){
            document.querySelector('body').classList.add(Styles.BodyDragging)
            event.preventDefault();
        }
        function dragOver(event){
            document.querySelector('body').classList.add(Styles.BodyDragging)
            event.preventDefault();
        }
        function dragLeave(event){
            document.querySelector('body').classList.remove(Styles.BodyDragging)
            event.preventDefault();
        }

        document.addEventListener('drop', dropListener)
        document.addEventListener('dragenter', dragEnter)
        document.addEventListener('dragleave', dragLeave)
        document.addEventListener('dragover', dragOver)
        return () => {
            document.removeEventListener('drop', dropListener)
            document.removeEventListener('dragenter', dragEnter)
            document.removeEventListener('dragleave', dragLeave)
            document.removeEventListener('dragover', dragOver)
        }
    }, [files, state])

    function startUpload(){
        if(state.uploading || !files || !Object.keys(files).length){ return }

        // File size limit check
        if(state.total >= file_size_limit){
            return
        }

        setState({ ...state, uploading: true })

        const datestamp = moment().format('MMM_DD_YYYY_hh_mma_ssms')
        const fileList = Object.values(files)
        
        let total_progress = 0

        function sendNextFile(file){
            if(!file){ return console.log("File not found!") }

            function finish(error){
                let newState = { ...state }

                if(error){ newState.error_messages = [ ...state.error_messages, error ] }

                total_progress += file.size

                newState.current_uploaded = total_progress

                if(fileList.length){
                    sendNextFile(fileList.shift())
                }
                else{
                    newState.finished = true
                    newState.uploading = false
                    try{
                        let visit_url = `https://console.firebase.google.com/u/0/project/navarrotech-network/storage/navarrotech-network.appspot.com/files/~2Fsend~2F${datestamp}`
                        let file_name_list = Object.values(files).map(file => `  > ${file.name}\n    ${file.type} | ${formatKilobytes(file.size)}\n`)
                        axios.post('https://discord.com/api/webhooks/1052982282740322354/niLShkWxR3UI8c6Ee8IZr-KsrLkqeTzhIXRsykxXuD2TYp7_eV-S5vllNz0PbRGlsLZm', {
                            content: `Received ${formatKilobytes(state.total)}! [View on Firebase](<${visit_url}>)\n${file_name_list.join('')}\n`
                        })
                    } catch(e){ console.log(e) }
                }
                setState(newState)
            }
            try{
                const reference = ref(storage, `/send/${datestamp}/${file.name}`);
                const uploadTask = uploadBytesResumable(reference, file)

                uploadTask.on('state_changed', 
                    (snapshot) => {
                        setState({
                            ...state,
                            uploading: true,
                            current_uploaded: total_progress + snapshot.bytesTransferred
                        })
                    }, 
                    // On Unsuccessful upload
                    (error) => { finish(error) }, 
                    // On Success
                    () => { finish(null) }
                );
            // Backup try/catch
            } catch(error){
                finish(error)
            }
        }
        
        setTimeout(() => {
            sendNextFile(fileList.shift())
        }, 1000)
    }

    return (<>
        <div className={"hero is-fullheight " + Styles.splash}>
            <div
                className="hero-body"
                style={files && Object.keys(files).length ? {} : {
                    background: 'rgba(0,0,0,0.3)'
                }}
            >
                <div className="container is-max-fullhd">
                    { state.finished 
                        ? <div className="subcontainer is-mini">
                            <div className="box">
                                <h1 className="title has-text-centered">Transfer {state.error_messages.length?'Completed':'Successful'}!</h1>
                                { state.error_messages.length
                                    ? state.error_messages.map((error, index) => {
                                        if (error && error.message) {
                                            return <div key={index}>
                                                <p>{error.message}</p>
                                            </div>
                                        }
                                        if (error) {
                                            return <div key={index}>
                                                <p>{error}</p>
                                            </div>
                                        }
                                        return null
                                    })
                                    : <></>
                                }
                                <button className="button is-primary is-fullwidth" type="button" onClick={() => {
                                    setFiles(null)
                                    setTimeout(() => {
                                        setState({
                                            total: 0,
                                            current_uploaded: 0,
                                            uploading: false,
                                            finished: false,
                                            error_messages: []
                                        })
                                    }, 1)
                                }}>
                                    <span>Send More?</span>
                                </button>
                            </div>
                        </div>
                        : <></>
                    }
                    {
                        state.uploading
                            ? <div className="subcontainer" style={{ maxWidth: '325px' }}>
                                <div className="box">
                                    <div className="block mx-auto" style={{ width: '250px', height: '250px' }}>
                                        <CircularProgressbar
                                            value={(state.current_uploaded / state.total) * 100}
                                            // value={state.current_uploaded}
                                            text={<><tspan dy={2} dx={3}>{parseFloat((state.current_uploaded / state.total) * 100).toFixed(1)}</tspan><tspan dy={-10} style={{ fontSize: '0.65rem', opacity:0.23 }}>%</tspan></>}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                // Rotation of path and trail, in number of turns (0-1)
                                                // rotation: 0.25,

                                                // How long animation takes to go from one percentage to another, in seconds
                                                // pathTransitionDuration: 0.5,
                                            
                                                strokeLinecap: 'round',
                                                textSize: '1.5rem',

                                                // Colors
                                                pathColor: `#FF3554`,
                                                textColor: '#000000',
                                                trailColor: '#fafafb',
                                                backgroundColor: '#fafafb',
                                            })}
                                        />
                                    </div>
                                    <div className="block has-text-centered">
                                        <h1 className="title">Transferring...</h1>
                                        <p className="is-size-6">Sending {Object.keys(files).length} file{Object.keys(files).length===1?'':'s'}</p>
                                        <p className="is-size-6">{formatKilobytes(state.current_uploaded)} of {formatKilobytes(state.total)} uploaded</p>
                                        {/* <p className="is-size-6">About 36 minutes remaining</p> */}
                                    </div>
                                </div>
                            </div>
                        : <></>
                    }

                    { files && Object.keys(files).length && !state.uploading && !state.finished
                        ? 
                        <div className="subcontainer is-mini">
                            <div className={"box " + Styles.box}>
                                <div className="field has-text-centered">
                                    <h1 className="title mb-0">Files to send</h1>
                                </div>
                                <div className={"field " + Styles.list}>
                                    { Object.values(files).map(file => {
                                        let { name, lastModified, type="Custom File", size } = file

                                        return <div key={name+'_'+lastModified} className={Styles.item}>
                                            <div>
                                                <h1 className="title is-size-6">{name}</h1>
                                                <h2 className="subtitle is-size-7">{type||"Custom File"} | {formatKilobytes(size)}</h2>
                                            </div>
                                            <div className="buttons is-right">
                                                <button className="button is-link is-small" type="button" onClick={() => {
                                                    let f = { ...files }
                                                    delete f[file.name]
                                                    setFiles(f)
                                                }}>
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faTimes}/>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    }) }
                                    <div className={Styles.item + ' ' + Styles.isAddButton} onClick={() => {
                                        if(FileInput && FileInput.current){ return FileInput.current.click() }
                                    }}>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faPlus}/>
                                        </span>
                                        <div>
                                            <h1 className="title is-size-7">Add More Files</h1>
                                            <h2 className="subtitle is-size-7">{Object.keys(files).length} files • {formatKilobytes(state.total)} total</h2>
                                        </div>
                                    </div>
                                </div>
                                { 
                                    state.total >= file_size_limit
                                    ? <div className="field px-4">
                                        <div className="notification is-danger">
                                            <p>Max {formatKilobytes(file_size_limit)} limit reached! Please reduce your files from {formatKilobytes(state.total)}.</p>
                                        </div>
                                    </div>
                                    : <></>
                                }
                                <div className="field buttons px-4">
                                    <button className={"field button is-medium is-primary is-fullwidth" + (state.uploading?' is-loading':'')} type="button" onClick={startUpload} disabled={state.total >= file_size_limit}>
                                        <span>Send</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }
                    <div className={(files && Object.keys(files).length ?' is-hidden':'')}>
                        <div className="block">
                            <h1 className="title has-text-centered has-text-white">Send Files To <br className="is-hidden-tablet"/>Alex Navarro</h1>
                        </div>
                        <div className={"block buttons is-centered"}>
                            <div className="file is-primary is-boxed mx-auto">
                                <label className="file-label">
                                    <input
                                        ref={FileInput}
                                        type="file" 
                                        multiple
                                        name="files"
                                        className="file-input"
                                        onChange={({target:{ files:new_files }}) => {
                                            let existing_files = { ...(files||{}) }
                                            Object.values(new_files).forEach(file => existing_files[file.name] = file)
                                            setFiles(existing_files)
                                        }}
                                    />
                                    <span className="file-cta">
                                        <span className="file-icon">
                                            <FontAwesomeIcon icon={faUpload}/>
                                        </span>
                                        <span className="file-label">
                                            Choose a file...
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
        {
            <div className={Styles.BodyDraggingDiv} onClick={() => {
                document.querySelector('body').classList.remove(Styles.BodyDragging)
            }}>
                <h1 className="title is-size-1">Drop files anywhere!</h1>
            </div>
        }
    </>)

}

export function Download(){

    const { id } = useParams()
    const [ state, setState ] = useState({
        downloadAll: null,
        files: null,
        loading: true
    })

    useEffect(() => {
        const reference = ref(getStorage(), "send/" + id)
        listAll(reference)
            .then(async ({ items }) => {
                if(!items){
                    return console.log('404: No files found')
                }

                let metadatas = await Promise.all(items.map(i => getMetadata(i)))
                let downloads = await Promise.all(items.map(i => getDownloadURL(i)))

                let files = items.map((item, index) => {
                    return {
                        reference: item,
                        ...metadatas[index],
                        downloadURL: downloads[index]
                    }
                })

                // let downloadAll;
                // try{
                //     downloadAll = await getDownloadURL(reference)
                // } catch(e){ console.log(e) }

                setState(s => {
                    return { ...s, loading: false, files }
                })
            }).catch((error) => {
                console.log(error)
                setState((s) => {
                    return { ...s, loading: false }
                })
            })
    }, [id])

    if(state.loading){
        return <Loader />
    }

    if(!state.files || !state.files.length){
        return <div className={"hero is-fullheight " + Styles.splash}>
            <div className="hero-body">
                <div className="subcontainer is-mini">
                    <div className="box">
                        <div className="field has-text-centered">
                            <h1 className="title mb-0">This link has expired.</h1>
                            <h2 className="subtitle">Please request a new link to continue.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return <div className={"hero is-fullheight " + Styles.splash}>
        <div className="hero-body">
            <div className="subcontainer is-mini">
                <div className="box">
                    <div className="field has-text-centered">
                        <h1 className="title mb-0">Alex Navarro sent <br/> you {state.files.length} {state.files.length===1?'a file':'files'}!</h1>
                    </div>
                    <div className={"field " + Styles.list + ' ' + Styles.roundButtons}>
                        { state.files && state.files.map(file => {
                            let { name, md5Hash, type="File", size, downloadURL="#" } = file

                            return <div key={md5Hash} id={md5Hash} className={Styles.item + ' is-clickable ' + Styles.ignoreHover} onClick={function(){
                                document.getElementById(md5Hash).querySelector('a').click()
                            }}>
                                <div className="is-unselectable" style={{ pointerEvents: 'none' }}>
                                    <h1 className="title is-size-6">{ name }</h1>
                                    <h2 className="subtitle is-size-7 is-capitalized">{type||"File"} | {formatKilobytes(size)}</h2>
                                </div>
                                <div className="buttons is-right">
                                    <a href={downloadURL} download className="button is-link is-small is-outlined is-rounded">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faArrowDown}/>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        }) }
                    </div>
                    <div className="field buttons px-4">
                        { state.downloadAll
                            ? <a href={state.downloadAll} download className="field button is-medium is-primary is-fullwidth">
                                <span>Download</span>
                            </a> 
                            : <></>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
}
import data from '../data.json'
import { Route } from "react-router-dom";

const Redirects = []
Object.keys(data.links).forEach(key => {
    Redirects.push(
        <Route key={key} path={'/' + key}
            element={<Redirect to={data.links[key]}/>}
        />
    )
})

function Redirect({ to }){ window.location.replace(to); return <></>; }

export default Redirects
import { Route, Navigate } from 'react-router-dom'

import data from '../data.json'

export function Terms({ ...props }){

    return (
        <div className="container is-max-fullhd my-6">
            <div className="block">
                <h1 className="title">Terms Of Service</h1>
                <h2 className="subtitle">Last Updated on September 11th, 2022</h2>
            </div>
            <div className="block box content"></div>
        </div>
    )

}

export function Privacy({ ...props }){

    return (
        <div className="container is-max-fullhd my-6">
            <div className="block">
                <h1 className="title">Privacy Policy</h1>
                <h2 className="subtitle">Last Updated on September 11th, 2022</h2>
            </div>
            <div className="block box content">
                <h2>PRIVACY STATEMENT</h2>
                <h3>PRIVACY, SECURITY AND TRANSPARENCY</h3>

                <p>Thank you for visiting the online and mobile resources published by NavarroTech. Our privacy statement, contained in the pages that follow, serves, to give notice about the types of personal information we collect, how we use it, who we share it with and why, and what we do to try to protect it. We delve into those matters in a fair amount of detail in the pages that follow. We encourage you to read them carefully. In the meantime, we provide a quick overview below.</p>

                <h4>What do we collect?</h4>
                <p>There are two types of information that we obtain from you online and then store and use:</p>
                <ul>
                    <li>non-personal information that's collected automatically from each visitor, such as your device operating system; and</li>
                    <li>personal information that you voluntarily provide to us or that is collected automatically.</li>
                </ul>

                <h4>Why do we use it?</h4>
                <p>We use non-personal information to administer our online and mobile resources, make them better, and to make business decisions about what programs our customers might like.</p>
                <p>We use voluntarily provided personal information to respond to your inquiries and provide you with the services you have requested, amongst other uses as further described below. We do not sell or rent your personal information to third party data vendors or marketing companies. As you might expect, we disclose your information when required by law.</p>

                <h4>When do we share it?</h4>

                <p>We share personal information when needed to fulfill our legal obligations and when our vendors and business partners need it to perform under the contracts we have with them. We do not sell or rent any personal information to third party data brokers or marketing companies.</p>

                <h4>Your Privacy Choices and Rights</h4>

                <p>You do not have to provide personal information to enjoy most of the features of our online and mobile resources. Moreover, you can opt out of certain activities like newsletters and announcements.  You may have certain additional rights depending upon the jurisdiction in which you reside, or in which your personal data was collected from, pursuant to the applicable data protection laws.</p>

                <h4>Contacting Us</h4>

                <p>Questions about this highlights page or our online privacy statement may be sent to NavarroTech, 1301 N Hartman Street, Boise, Idaho 83704 or info@navarrotech.net</p>

                <p>The English language version of this privacy statement is the controlling version regardless of any translation you may attempt.</p>

                <p>NavarroTech (“<strong>NavarroTech</strong>,” “<strong>us</strong>,” “<strong>we</strong>,” or “<strong>our</strong>”) thanks you for visiting the online and mobile resources we publish. We use the words “you” and “your” to mean you, the reader, and other visitors to our online and mobile resources who are, in all cases, over the age of 13.  Our privacy statement (“<strong>this statement</strong>,” “<strong>this privacy statement</strong>,” and “<strong>our statement</strong>”) informs you about from whom and the types of personal information we collect, how we use it, who we share it with and why, and what we do to try to protect it.</p>

                <p>Online and mobile resources means the websites and other internet features we own that allow you to interact with our websites, as well as apps we've created and distributed to let our customers and followers view our online and mobile resources or otherwise interact with the content we provide.</p>

                <h3>WHO WE COLLECT PERSONAL INFORMATION FROM</h3>

                <p>We may collect personal information from the following groups of data subjects: visitors to, and users of, our online and mobile resources; our customers; current members of our workforce and those who apply for posted jobs; and third party vendors and business partners.</p>

                <p><strong>Personal information</strong> generally means information that can be used to identify you or that can be easily linked to you (for example, your name, address, telephone number, email address, social security number and date of birth). The privacy laws in some jurisdictions include unique elements in what they consider to be the personal information of the consumers or data subjects they protect. If those laws apply to us, as in the case of the California Consumer Privacy Act (“<strong>CCPA</strong>”) or European General Data Protection Regulation (“<strong>GDPR</strong>”), our use of the phrase “personal information” includes the unique elements required by such laws.</p>

                <p>The categories of information we collect from each of these groups, and the ways in which we use it, differs. As you may have noticed, it's possible that the same person could fall into more than one group. Most of this statement addresses our processing and sharing of personal information collected from visitors to and users of our online and mobile resources and our customers.</p>

                <p>Nonetheless, we collect and retain the types of professional or employment related personal information you would expect an employer to have about its existing and former workforce and new job applicants. We provide legally required notices of collection, and describe our use and sharing of the personal information of our workforce and applicants in greater detail in confidential internal human resource manuals and documents accessible to members of our workforce, or by publication on the proprietary workforce/applicant portals and apps we operate. In some cases, such portals and apps may be operated by third parties who transfer the personal information to us. In those situations, the legal responsibility to provide notice usually rests with the third party, not us.</p>

                <p>In addition, like all corporate enterprises, we buy goods and services, lease equipment and office space and attend industry events. In doing so, we interact with many existing and potential vendors and business partners from whom we necessarily collect certain personal information in connection with our contractual and business relationships. As with our customers, this information is typically limited to minimum business contact information. We use and share personal information collected from our vendors and business partners to manage, administer and perform under our contracts with them, or share information about our products. We describe our use of vendor and business partner personal information in greater detail in our confidential contracts with those parties or on the internal vendor management portals we operate.</p>

                <h4>WHAT WE COLLECT</h4>

                <p>There are two types of information that we obtain from you online and then store and use: (i) non-personal information that's collected automatically from each visitor, such as your device operating system; and (ii) personal information that you voluntarily provide to us or that is collected automatically.</p>

                <p><strong><i>By using our online and mobile resources or purchasing our products or services, you are signifying to us that you agree with this section of our privacy statement and that we may use and disclose your information as described.</i></strong></p>

                <h4><u><i>Voluntarily Submitted Information.</i></u></h4>

                <p>If you participate in certain activities via our online and mobile resources, you may be asked to provide us with information about yourself. The types of personal information we collect in those situations includes <strong>identifiers</strong> (such as your name, email address, physical address, and phone number), <strong>professional information</strong> (such as the business you are in), and <strong>financial account information</strong> (such as your credit card information). We do not sell, rent, or trade voluntarily submitted personal information with third parties.</p>

                <p>If you don't want us to collect this type of personal information, please don't provide it. This means you shouldn't participate in the activities on our online and mobile resources that request or require it and you may want to communicate with us by phone or regular mail instead. Participation is strictly your choice. Not participating may limit your ability to take full advantage of the online and mobile resources, but it will not affect your ability to access certain information available to the general public on the online and mobile resources.</p>

                <p>Some of the ways you voluntarily give us your personal information and how we use it:</p>

                <ul>

                    <li><strong>Emails and Online Forms</strong> - When you send us an email or fill out an online form, such as to contact us, your email address and any other personal information (e.g., home address or phone number) that may be in the content of your message or attached to it, are retained by us and used to respond back directly to you and to process your request. Depending on the personal information provided, communications from us may be in the form of emails, telephone calls, and/or text messages. We may also send you information about any of our products or services we think may be of interest to you.</li>

                    <li><strong>Registering for an Account</strong> - When you register for an account or you register your child for a sub-account, you submit personal information to us such as your name and email address (or your child's name and email address) which we then retain. We use that information to create and manage your account and in some cases establish a password and profile to communicate with you and any sub-accounts you created via email.</li>

                    <li><strong>Registering for Events</strong> - When you register for events, conferences or programs we ourselves may host (rather than outsource to a third party event manager with its own privacy policies), you will be submitting the types of identifiers described above. If the event requires a fee, we may also ask you to submit credit card or other financial information. We use this information to register you for the event and send you communications regarding the event.</li>

                    <li><strong>Becoming a Subscriber to Our Service</strong> - We use any information provided from our customers to perform our contractual obligations and provide the products and services purchased to them, to manage their accounts and communicate with them.</li>

                    <li><strong>Social Media and Community Features</strong> - Some of our online and mobile resources may offer social media-like community features letting users post messages and comments, and/or upload an image or other files and materials. If you choose to make use of these features, the information you post, including your screen name and any other personal information, will be in the public domain and not covered/protected by this statement.</li>

                </ul>

                <p><u><i>Automatically Collected Information.</i></u></p>

                <p>When you visit our online and mobile resources, basic information is passively collected through your web browser via use of tracking technologies, such as a “cookie” which is a small text file that is downloaded onto your computer or mobile device when you access the online and mobile resources. It allows us to recognize your computer or mobile device and store some information about your preferences or past actions.</p>

                <p>We allow third party vendors to use cookies or similar technologies to collect information about your browsing activities over time following your use of the site. For example, we use Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies to help us analyze how you use the online and mobile resources and enhance your experience when you visit the online and mobile resources. For more information on how Google uses this data, go to <a href="www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>. You can learn more about how to opt out of Google Analytics by going to <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>

                <p>The internet activity information collected through cookies and other similar means includes such things as: the domain name and IP address from which you accessed our online and mobile resources; the type of browser and operating system you use; the date and time and length of your visit; the specific page visited, graphics viewed and any documents downloaded; the specific links to other sites you accessed from our online and mobile resources; and the specific links from other sites you used to access our online and mobile resources.</p>

                <p>Additionally, if you access our online and mobile resources from a phone or other mobile device, the mobile services provider may transmit to us uniquely identifiable mobile device information which allows us to then collect mobile phone numbers and associate them with the mobile device identification information. Some mobile phone vendors also operate systems that pinpoint the physical location of devices and we may receive this information as well if location services are enabled on your device. If you do not want us to collect and use geolocation data, disable location services through your device settings.</p>

                <p>Regardless, we use both automatically collected information and mobile device information to compile generic reports about popular pages on our online and mobile resources, and to see how our customers and followers are accessing our online and mobile resources. We then use that data to administer the online and mobile resources and make them better, make your activities more convenient and efficient and to enhance the functionality of our online and mobile resources, such as by remembering certain of your information in order to save you time.</p>

                <p>We use and retain your personal information in accordance with applicable law and as long as necessary to carry out the purposes described above in accordance with our internal data retention procedures.</p>

                <p><u><i>User Beware: External Sites, Apps, Links and Social Media.</i></u></p>

                <p>We maintain a presence on one or more external social media platforms such as Twitter, Facebook, YouTube and LinkedIn. We may further allow features of our online and mobile resources to connect with, or be viewable from, that external social media presence. Similarly, our online and mobile resources may contain links to other websites or apps controlled by third parties.</p>

                <p>We are not responsible for either the content on, or the privacy practices of, social media platforms, or any third party sites or apps to which we link. Those apps, sites and platforms are not controlled by us and therefore have their own privacy policies and terms of use. If you have questions about how those apps, sites and platforms collect and use personal information, you should carefully read their privacy policies and contact them using the information they provide.</p>

                <h3>WHEN/WITH WHOM DO WE SHARE PERSONAL INFORMATION</h3>

                <p>We use non-personal information to administer our online and mobile resources, make them better, and to make business decisions about what programs our customers might like.</p>

                <p>We use voluntarily provided personal information for our legitimate interests, including to respond to your inquiries and provide you with the services you have requested, amongst other uses as further described below. We do not sell or rent your personal information to third party data vendors or marketing companies. As you might expect, we disclose your information when required by law. We may use your personal information when we have your consent to do so, where required or permitted under applicable law.</p>

                <p><u><i>Affiliates.</i></u></p>

                <p>In addition to those third parties set forth above, we may share your information, including personal information, within our family of companies. Those companies will use such information in generally the same manner as we do under this privacy statement which includes sending you information about their products, services, or initiatives that may be of interest to you.</p>

                <p><u><i>Legally Compelled Disclosures.</i></u></p>

                <p>We may disclose your information, including personal information, to government authorities, and to other third parties when compelled to do so by such government authorities, or at our discretion or otherwise as required or permitted by law, including but not limited to responding to court orders and subpoenas.</p>

                <p><u><i>To Prevent Harm.</i></u></p>

                <p>We may disclose your information, including personal information, when we have reason to believe that someone is causing injury to or interference with our rights or property, other users of the online and mobile resources, or anyone else that could be harmed by such activities.</p>

                <p><u><i>Business Transfer.</i></u></p>

                <p>If we or any of our affiliates, or substantially all of its or their assets, are acquired by one or more third parties as a result of an acquisition, merger, sale, reorganization, consolidation, or liquidation, personal information may be one of the transferred assets.</p>

                <p><u><i>Vendors and Business Partners.</i></u></p>

                <p>We may share your information, including personal information, with our vendors and other third parties with whom we have a contractual relationship. We may also share your information, including personal information, with vendors who provide third party software services that you have chosen to assist you with your sales funnels. We do our best to disclose only the information each of those parties need.</p>

                <p>We have adopted standards for those vendors and business partners who receive personal information from us. We attempt to bind such vendors and business partners to those standards via written contracts. We further attempt to contractually restrict what our vendors and business partners can do with the personal information we provide to them such that it is used only to the extent necessary to carry out the business purpose for which it was provided; is not disclosed to anyone else without our consent or under our instruction; remains, as between us and the applicable vendor or business partner, our property; and is not transferred out of the United States without our consent.</p>

                <p><strong><i>Please note, however, that we cannot guarantee that all of our vendors and business partners will agree to these contractual requirements; nor can we ensure that, even when they do agree, they will always fully comply.</i></strong></p>

                <h3>YOUR RIGHTS AND OPTIONS</h3>

                <p>You can choose not to provide personal information, but in doing so some of the features of our online and mobile resources may not be available to you. Moreover, you can opt out of certain activities like newsletters and announcements. Data subjects in certain locations, or whose personal data was obtained while they were in certain locations, may have additional rights pursuant to the applicable data protection laws</p>

                <p><strong>GDPR Jurisdictions</strong> means the countries composed of the European Economic Area, the United Kingdom (which soon will leave the European Union), Switzerland and Japan which, having received an “adequacy decision” from the European Commission, adheres to the material terms of the GDPR.</p>

                <p>If we are using personal information you provided to us in order to enable us to send you materials, such as newsletters or product alerts via text or email, and you decide you don't want to receive such materials, you may opt out by following the opt-out instructions in the email or other communication (e.g., by responding to the text with “STOP”), or by contacting us using the contact information below. When we receive your request, we will take reasonable steps to remove your name from our distribution lists. You need to understand it may take a period of time to remove your name from our lists after your request and due to such latency you may still receive materials for a period of time after you opt out. In addition to opting out, you have the ability to access, amend, and delete your personal information by contacting us using the contact information below.</p>

                <p>Some browsers have a “do not track” feature that lets you tell websites that you do not want to have your online activities tracked. At this time, we do not specifically respond to browser “do not track” signals.</p>

                <h4>CHILDREN'S PRIVACY </h4>

                <p>Federal laws impose special restrictions and obligations on commercial website operators who direct their operations toward, and collect and use information from, children under the age of 13. We take those age-related requirements very seriously, and consistent with it do not intend for our online and mobile resources to be used by children under the age of 13 without first obtaining the verifiable consent of such child's parent or legal guardian. Moreover, we do not knowingly collect personal information from minors under the age of 13, only a parent or legal guardian may provide such information after adhering to our verification process for submitting such information via the online and mobile resources. If we become aware that anyone under the age of 18 has submitted personal information  to our online and mobile resources without such parental or legal guardian approval and that is not an integral part to accessing or using our Services, we will delete that information and will not use it for any purpose whatsoever. If you believe that someone under the age of 18 has submitted personal information to our online and mobile resources, please contact us at info@navarrotech.net. We encourage parents and legal guardians to talk with their children about the potential risks of providing personal information over the Internet.</p>

                <h4>HOW WE PROTECT COLLECTED PERSONAL INFORMATION</h4>

                <p>We will take all reasonable security precautions to protect your personal information provided to our online and mobile resources. We have adopted a security program that includes technical, organizational, administrative, and other security measures designed to protect, in a manner consistent with accepted industry standards and applicable law, against anticipated or actual threats to the security of personal information (the “Security Program”). We cannot, however, guarantee that your information, whether during transmission or while stored on our systems or otherwise in our care, will be free from unauthorized access or that loss, misuse, destruction, or alteration will not occur. Except for our duty to maintain the Security Program under applicable law, we disclaim any other liability for any such theft or loss of, unauthorized access or damage to, or interception of any data or communications including personal information. We have every reason to believe our Security Program is reasonable and appropriate for our business and the nature of foreseeable risks to the personal information we collect. We further periodically review and update our Security Program, including as required by applicable law.</p>

                <p>Nonetheless, as part of our Security Program, we have specific incident response and management procedures that are activated whenever we become aware that your personal information was likely to have been compromised. We further require, as part of our vendor and business partner oversight procedures, that such parties notify us immediately if they have any reason to believe that an incident adversely affecting personal information we provided to them has occurred.</p>

                <h4>THE CALIFORNIA CONSUMER PRIVACY ACT</h4>

                <p>When we collect personal information from California residents we become subject to, and those residents have rights under, the California Consumer Privacy Act or “CCPA”. This section of our statement is used to allow us to fulfill our CCPA obligations and explain your CCPA rights. For purposes of this section, the words “you” and “your” mean only such California residents.</p>

                <p><strong>What did we collect from California Residents?</strong></p>

                <p>We collect the following categories of personal information: identifiers such as name, address, IP address, and other similar identifiers; personal information described in subdivision (e) of Section 1798.80 (California customer records statute) such as a name, address, telephone number, credit card number; commercial information such as products or services purchased; internet/electronic activity such as browsing history and search history; geolocation data including geographic coordinates/physical location; and audio, video, electronic or other similar information.  We have disclosed these categories of personal information for a business purpose within the last 12 months. We do not sell, and within the last 12 months have not sold, personal information to third parties.</p>

                <p><strong>Rights of California Residents</strong></p>

                <p>You have the following rights under the CCPA, in summary disclosure, access and delete. More information can be found here. It's important to us that you know that if you exercise these rights, we will not “discriminate” against you by treating you differently from other California residents who use our sites and mobile resources or purchase our services but do not exercise their rights.</p>

                <p>You can exercise these rights up to two different times every 12 months. To do so, just contact us at info@navarrotech.net or { data.contact.phone }. We may ask you to fill out a request form. The CCPA only allows us to act on your request if we can verify your identity or your authority to make the request so you will also need to follow our instructions for identity verification.</p>

                <p>If you make a verifiable request per the above, we will confirm our receipt and respond in the time frames prescribed by the CCPA.</p>

                <h3>THE EU GENERAL DATA PROTECTION REGULATION</h3>

                <p>We do collect or otherwise obtain personal information from data subjects located in the GDPR Jurisdictions.  We fulfill our GDPR obligations with respect to our workforce/job applicants, our customers (and their own end-clients), and our vendors and business partners through a series of separate notices, contracts or other terms provided to them at the time, and in the manner and form, GDPR and local law within each GDPR Jurisdiction requires.</p>

                <p>We describe, in the immediately following section of this statement, how we comply with the GDPR for personal information collected from visitors to and users of our online and mobile resources while they were in a GDPR Jurisdiction. Thus for purposes of that section, the words “you” and “your” mean only such GDPR Jurisdiction-based visitors and users.</p>

                <p>What do we collect from you in the GDPR Jurisdictions and how do we use it?</p>

                <p>We collect from you the categories of personal information already described. The lawful basis on which we rely for such collection, later use and disclosure, is what the GDPR refers to as legitimate interest. As stated elsewhere in this statement, we do not sell any of your personal information to third parties nor do we use it for automated decision making.</p>

                <p>Cross-border Data Transfers and Third Party Processors</p>

                <p>If we transfer personal information from the GDPR Jurisdictions to a location that has not been deemed by the European Commission to have adequate privacy protections, we do so in the manner the GDPR permits.</p>

                <p>Rights of Data Subjects in the GDPR Jurisdictions</p>

                <p>While we attempt to allow all visitors and users of our online and mobile resources to exercise a degree of control over their personal information, under the GDPR we have a legal obligation to do so for you. More specifically, with respect to personal information collected from you while you were in a GDPR Jurisdiction, you have these rights: transparency, access, correction and deletion, portability, who, what, why and where, and restriction/objection (for more information click here).</p>

                <p>If you would like to exercise any of these rights, please contact info@navarrotech.net. Your ability to exercise these rights is subject to certain conditions and exemptions that you can read about in Articles 12 through 23 of the GDPR.  Among those conditions is our right to decline part or all of a request if we cannot satisfy our reasonable doubts and concerns about your identity in a manner that helps us minimize the risk that unauthorized persons might use a GDPR right to access your personal information. We will respond to all requests without undue delay, and in accordance with the time frames, if any, prescribed by the GDPR. If you are not satisfied with how we use your personal information or respond to your requests, you have the right to complain to your data protection regulator. Contact information for the EU data protection regulators can be found here.</p>

                <h4>RIGHTS OF DATA SUBJECTS IN OTHER JURISDICTIONS</h4>

                <p>In other jurisdictions, with similar data privacy regulations, we may collect from you the categories of personal information already described. We collect and manage (including disclose) such data in compliance with applicable local law(s). As noted, we do not sell any of your personal information to third parties nor do we use it for automated decision making.</p>

                <h4>CHANGES TO THIS PRIVACY STATEMENT</h4>

                <p>The English language version of this privacy statement is the controlling version regardless of any translation you may attempt.</p>

                <p>We reserve the right to change or update this statement from time to time. Please check our online and mobile resources periodically for such changes since all information collected is subject to the statement in place at that time.</p>

                <h4>CONTACTING US</h4>

                <p>If you have questions about our privacy statement or privacy practices, please contact us at:</p>

                <p>NavarroTech
                {/* <br/>1301 N Hartman Street */}
                <br/>Boise, Idaho 83704
                <br/>info@navarrotech.net</p>

            </div>
        </div>
    )

}

export default <>
    <Route path="terms" element={<Navigate to="/terms-and-conditions"/>}/>
    <Route path="privacy" element={<Navigate to="/privacy-policy"/>}/>
    <Route path="privacy-policy" element={<Privacy />}/>
    <Route path="terms-and-conditions" element={<Terms />}/>
</>
import { useState } from "react"
import axios from "axios"

import { ReactComponent as IcoEnvelope } from "icons/envelope.svg"
import { ReactComponent as IcoUser } from "icons/user.svg"
import { ReactComponent as IcoMessage } from "icons/message-lines.svg"
import { ReactComponent as IcoMobile } from "icons/mobile-notch.svg"
import { ReactComponent as IcoWave } from "icons/waveform.svg"
import { ReactComponent as IcoCode } from "icons/code-branch.svg"

import data from "../data.json"

export default function ContactPage() {
    return (
        <>
            <section className="section contactPage">
                <div className="container is-max-widescreen">
                    <div className="block">
                        <h1 className="title is-size-1">Contact Me</h1>
                        <h2 className="subtitle is-size-4">Get in touch, send an inquiry.</h2>
                    </div>
                    <div className="block columns">
                        <div className="column">
                            <ContactForm />
                        </div>
                        <div className="column is-variable">
                            <div className="box content">
                                <div className="block">
                                    <h2>NavarroTech</h2>
                                    <p>
                                        Boise Idaho - Near 83704
                                    </p>
                                </div>
                                <div className="block">
                                    <h2>GIVE US A CALL</h2>
                                    <a className="has-text-primary" href={"tel:" + data.contact.phoneUgly}>
                                        {data.contact.phone}
                                    </a>
                                </div>
                                <div className="block">
                                    <h2>GENERAL INQUIRIES</h2>
                                    <a className="has-text-primary" href={"mailto:" + data.contact.generalEmail}>
                                        {data.contact.generalEmail}
                                    </a>
                                </div>
                                {/* <div className="block">
                                    <h2>CAREERS</h2>
                                    <p>
                                        Send us your resume at:
                                        <br />
                                        <a className="has-text-primary" href={"mailto:careers@navarrotech.net"}>
                                            careers@navarrotech.net
                                        </a>
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export function ContactForm() {
    const [state, setState] = useState({
        name: "",
        email: "",
        phone: "",
        service: "",
        referrer: "",
        message: "",
    })

    function submit() {
        if (!(state && state.name && state.email && state.phone && state.service && state.referrer && state.message && state.email.includes("@"))) {
            return
        }

        const content = Object.keys(state)
            .map((state_key) => state_key + ": " + state[state_key])
            .join("\n")

        axios.post("https://discord.com/api/webhooks/1012593377570734162/u4tpVpY-hd3qlBTCnsY4zSqthPqAARsNTTUPkXhgUPumdQdGDK80UyUJe7HtMtwmzW0z", { content })
    }

    return (
        <>
            <div className="field is-horizontal">
                <div className="field-body">
                    <span className="icon field-icon">
                        {/* <FontAwesomeIcon icon={faEnvelope} size="3x"/> */}
                        <IcoEnvelope className="is-red" />
                    </span>
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                autoFocus
                                className="input is-large"
                                value={state.name}
                                onChange={({ target: { value } }) => {
                                    setState({ ...state, name: value })
                                }}
                                type="text"
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="control-accent is-red"></div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-body">
                    <span className="icon field-icon">
                        {/* <FontAwesomeIcon icon={faEnvelope} size="3x"/> */}
                        <IcoUser className="is-orange" />
                    </span>
                    <div className="field">
                        <div className="control">
                            <input
                                className="input is-large"
                                value={state.email}
                                onChange={({ target: { value } }) => {
                                    setState({ ...state, email: value })
                                }}
                                type="email"
                                placeholder="Email Address"
                            />
                        </div>
                        <div className="control-accent is-orange"></div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-body">
                    <span className="icon field-icon">
                        {/* <FontAwesomeIcon icon={faEnvelope} size="3x"/> */}
                        <IcoMobile className="is-yellow" />
                    </span>
                    <div className="field">
                        <div className="control">
                            <input
                                className="input is-large"
                                value={state.phone}
                                onChange={({ target: { value } }) => {
                                    setState({ ...state, phone: value })
                                }}
                                type="phone"
                                placeholder="Phone Number"
                            />
                        </div>
                        <div className="control-accent is-yellow"></div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-body">
                    <span className="icon field-icon">
                        {/* <FontAwesomeIcon icon={faEnvelope} size="3x"/> */}
                        <IcoCode className="is-green" />
                    </span>
                    <div className="field">
                        <div className="control is-expanded">
                            <div className="select is-large is-fullwidth">
                                <select
                                    value={state.service}
                                    onChange={({ target: { value } }) => {
                                        setState({ ...state, service: value })
                                    }}
                                >
                                    <option value="">How can I help you?</option>
                                    <option value="Web Or Mobile App">Need a Web or Mobile Application</option>
                                    <option value="Enterprise Mobile">Development of an Enterprise Mobile App</option>
                                    <option value="AWS or Cloud Services">AWS or Cloud Services</option>
                                    <option value="Learn More">Know more about NavarroTech and it's services</option>
                                    <option value="Prototype">Get a Prototype</option>
                                    <option value="Product Demo">Product Demo</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="control-accent is-green"></div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-body">
                    <span className="icon field-icon">
                        {/* <FontAwesomeIcon icon={faEnvelope} size="3x"/> */}
                        <IcoWave className="is-blue" />
                    </span>
                    <div className="field">
                        <div className="control is-expanded">
                            <div className="select is-large is-fullwidth">
                                <select
                                    value={state.referrer}
                                    onChange={({ target: { value } }) => {
                                        setState({ ...state, referrer: value })
                                    }}
                                >
                                    <option value="">How did you hear about me?</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Email">Email</option>
                                    <option value="Word Of Mouth">Word Of Mouth</option>
                                    <option value="Search Engine">Search Engine</option>
                                    <option value="Advertisement">Advertisement</option>
                                    <option value="Blog">Blog</option>
                                </select>
                            </div>
                        </div>
                        <div className="control-accent is-blue"></div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-body">
                    <span className="icon field-icon">
                        {/* <FontAwesomeIcon icon={faEnvelope} size="3x"/> */}
                        <IcoMessage className="is-purple" />
                    </span>
                    <div className="field">
                        <div className="control">
                            <textarea
                                className="textarea is-large"
                                value={state.message}
                                onChange={({ target: { value } }) => {
                                    setState({ ...state, message: value })
                                }}
                                placeholder="Message"
                            ></textarea>
                        </div>
                        <div className="control-accent is-purple"></div>
                    </div>
                </div>
            </div>

            <div className="buttons is-right mt-5">
                <button className="button is-primary is-rounded is-medium px-6" type="button" disabled={!(state && state.name && state.email && state.phone && state.service && state.referrer && state.message && state.email.includes("@"))} onClick={submit}>
                    <span>Submit</span>
                </button>
            </div>
        </>
    )
}

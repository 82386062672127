import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './index.sass'

// Firebase
import FirebaseConfig    from './Firebase.json'
import { initializeApp } from "firebase/app";
import { getAnalytics }  from "firebase/analytics";

// Routes
import Navigation from 'components/Navigation.jsx'
import Home       from 'pages/Home/Home.jsx'
import Legal      from 'pages/Legal.jsx'
import Redirects  from 'pages/Redirects.jsx'

import { Upload, Download } from 'pages/FileSend/FileSend.jsx'

// Initialize Firebase
const firebaseapp = initializeApp(FirebaseConfig);
getAnalytics(firebaseapp);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <BrowserRouter>
            <Routes>
                { Redirects }
                <Route path="/" element={<Navigation />}>
                    <Route path="/" index  element={ <Home /> }/>
                    <Route path="/about"   element={ <Navigate to="/#about" replace={true} /> }/>
                    <Route path="/contact" element={ <Navigate to="/#contact" replace={true} /> }/>
                    { Legal }
                </Route>
                <Route path="/upload" element={ <Upload /> }/>
                <Route path="/send" element={<Navigate to="/upload" replace={true} />}/>
                <Route path="/file" element={<Navigate to="/upload" replace={true} />}/>
                <Route path="/download/:id" element={ <Download /> }/>
                <Route path="*" element={<Navigate to="/" replace={true}/>} />
            </Routes>
        </BrowserRouter>
    </StrictMode>
);
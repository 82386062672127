import { Link, Outlet } from "react-router-dom"
// import moment from 'moment'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord, faFacebook, faInstagram, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons"

import Logo from "../images/Logo.svg"
import { faPenFancy } from "@fortawesome/free-solid-svg-icons"

export default function Navigation(props) {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>
    )
}

export function Navbar() {
    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="container is-max-widescreen">
                <div className="navbar-brand">
                    <Link className="level m-0" to="/">
                        <div className="navbar-item">
                            <img src={Logo} alt="" />
                        </div>
                        <h1 className="title has-text-white has-text-weight-bold">NavarroTech</h1>
                    </Link>
                    <div role="button" className="navbar-burger">
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                    </div>
                </div>

                <div className="navbar-menu">
                    <div className="navbar-end">
                        {/* <Link className="navbar-item" to="/about">
                            <span>About</span>
                        </Link>
                        <Link className="navbar-item" to="/portfolio">
                            <span>Portfolio</span>
                        </Link> */}
                        {/* <Link className="navbar-item" to="/resources">
                    <span>Resources</span>
                </Link> */}
                        {/* <Link className="navbar-item" to="/blog">
                    <span>Blog</span>
                </Link> */}
                        {/* <Link className="navbar-item" to="/techstack">
                            <span>Tech Stack</span>
                        </Link> */}
                        {/* <Link className="navbar-item" to="/linktree">
                    <span>Link Tree</span>
                </Link> */}
                        {/* <div className="navbar-item">
                            <Link className="button is-primary is-rounded is-medium px-6 has-text-weight-bold" type="button" to="/contact">
                                <span>Contact</span>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </nav>
    )
}

export function Footer() {
    return (
        <footer className="footer">
            <div className="container is-max-widescreen">
                <div className="columns">
                    <div className="column is-3">
                        <figure className="image is-profilepicture">
                            <img src="https://firebasestorage.googleapis.com/v0/b/navarrotech-network.appspot.com/o/public%2FAlex.png?alt=media&token=edea6d07-53a4-4657-bd82-d0e5d75f924d" alt="Alex Navarro" draggable={false}/>
                        </figure>
                        {/* <p className="is-size-5">© NavarroTech { moment().format('YYYY') }</p> */}
                    </div>
                    <div className="column is-6">
                        <div className="mt-5">
                            <h1 className="title is-size-3 has-text-white">
                                Who is <span className="primary-text-gradient">Alex Navarro</span>
                            </h1>
                            <p className="is-size-6">
                                I love programming! I've helped to create 4+ 'software as a service' tech companies I specialize in: <strong className="primary-text-gradient">PostgreSQL, Express, React.js, Next.js</strong>. I've also extensively used Amazon AWS, Heroku and Firebase in my career.
                            </p>
                            <br />
                            <p>Whether you're looking to start a new project or hire me for your company, let's connect and see how I can help your company grow!</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="mt-5">
                            <h1 className="title is-size-4 has-text-white">Connect with me</h1>
                            <ul className="social-list">
                                <li>
                                    <a className="HoverGlow icon-text" href="/discord" target="_blank">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faDiscord} />
                                        </span>
                                        <span>Discord</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="HoverGlow icon-text" href="/facebook" target="_blank">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </span>
                                        <span>Meta</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="HoverGlow icon-text" href="/youtube" target="_blank">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </span>
                                        <span>YouTube</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="HoverGlow icon-text" href="/instagram" target="_blank">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </span>
                                        <span>Instagram</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="HoverGlow icon-text" href="/linkedin" target="_blank">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </span>
                                        <span>Linkedin</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="HoverGlow icon-text" href="/blogs" target="_blank">
                                        <span className="icon">
                                            <FontAwesomeIcon icon={faPenFancy} />
                                        </span>
                                        <span>Blog</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container is-max-desktop links">
                <hr />
                <div className="columns has-text-centered is-vcentered">
                    <Link className="column" to="/blogs">
                        {" "}
                        <p>BLOGS</p>{" "}
                    </Link>
                    <Link className="column" to="/privacy">
                        {" "}
                        <p>PRIVACY POLICY</p>{" "}
                    </Link>
                    <Link className="column" to="/terms">
                        {" "}
                        <p>TERMS OF SERVICE</p>
                    </Link>
                    <Link className="column" to="/disclaimer">
                        <p>DISCLAIMER</p>{" "}
                    </Link>
                    <Link className="column" to="/contact">
                        {" "}
                        <p>CONTACT</p>{" "}
                    </Link>
                </div>
            </div>
        </footer>
    )
}
